<template>
  <div class="ele-body" style="padding:0">
    <el-card shadow="never">
      <div class="d-flex" style="justify-content: space-between;flex-wrap: nowrap;">
        <div>
					<div @click="initMap" style="cursor: pointer;color: #33cc99;margin-left: 20px;">返回中国地图 >></div>
          <div id="map" style="width:700px;height:600px;"></div>
          <div style="text-align:center">点击地图可查看省-市-区县的授权情况</div>
        </div>
        <div style="margin-top:4rem;margin-left: 20px;">
          <el-form :model="editForm" ref="editForm" :rules="editRules">
            <div class='title'>可蚁点平台运营权限查询</div>
            <div class='d-flex'>
              <el-form-item label="省" label-width="40px">
                <el-select v-model="editForm.pid" @change="handleChangeProv(editForm.pid)"
                    class="selectStyle input163" placeholder="请选择省">
                  <el-option v-for="option in provArr" :value="option.pid" :key="option.id"
                    :label="option.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="市" label-width="40px">
                <el-select v-model="editForm.cid" @change="handleChangeCity(editForm.cid)"
                    class="selectStyle input163" placeholder="请选择市">
                  <el-option v-for="option1 in cityArr" :value="option1.cid" :key="option1.cid"
                    :label="option1.name"></el-option>
                </el-select>
              </el-form-item>
							
              <el-form-item label="区" label-width="40px">
                <el-select v-model="editForm.aid" @change="$forceUpdate(),handleDis(editForm.aid)"
                           class="selectStyle input163 mr-20" placeholder="请选择县/区">
                  <el-option v-for="option2 in districtArr " :value="option2.aid" :key="option2.aid"
                             :label="option2.name"></el-option>
                </el-select>
              </el-form-item>
							<el-button @click="chongzhi()" style="padding: 0 20px;line-height: 0;height: 37px;">重置</el-button>
            </div>
						<div style="display: none;">{{editForm.aid}}{{EcountryID}}</div>
            <el-button @click="handleGrant(editForm.aid)" class="ele-btn-icon search" :disabled="(editForm.pid && editForm.cid)?false:true"
               :class="(editForm.pid && editForm.cid)?'green':'gray'">
              <div>授权查询</div>
            </el-button>
            <el-form-item>
              <div class="line"></div>
            </el-form-item>
            <div style="min-height:30rem;" v-if="isShow">
              <div class="content" v-if="grantIsshow">"{{proName}}"{{proType}}级代理运营商可申请运营授权，如需申请请填写以下信息
              </div>
              <div class='marleft' v-if="grantIsshow">
								<el-form-item label="选择运营区/县名称" label-width="140px" prop="aid">
									<el-select v-if="!editForm.aid" v-model="editForm.aid1" multiple :placeholder="editForm.aid1"
										:disabled='authQuery' @change="$forceUpdate(),handleChangeXQ()" clearable>
										<el-option v-for="item in districtArr" :key="item.aid" :label="item.name"
											:value="item.aid"></el-option>
									</el-select>
									<el-input v-else v-model="editForm.aid1" :placeholder="editForm.aid1" clearable disabled />
								</el-form-item>
                <el-form-item label="已选择运营区/县" label-width="140px">
                  <span v-if="editForm.aidArr || editForm.aid" class="content">{{editForm.aidArr}}</span>
									<span v-else class="content">无</span>
                </el-form-item>
								<el-form-item label="运营个数" label-width="140px">
									<el-input v-model="editForm.num" clearable disabled />
								</el-form-item>
                <el-form-item label="新运营授权账号" label-width="140px" prop="phone">
                  <el-input v-model="editForm.phone" placeholder="为避免授权问题，请填写新账号（手机号）" clearable />
                </el-form-item>
                <el-form-item label="运营授权费用" label-width="140px">
                  <span style="color:red">{{ymoney}}万元</span>
                </el-form-item>
                <el-form-item label="平台收款账户" label-width="140px">
                  <div style="display:flex;justify-content:space-between;flex-wrap: wrap;">
                    <span>依蓝云（北京）科技有限公司 </span>
                    <span>0126014210005430（中国民生银行）</span>
                  </div>
                </el-form-item>
              </div>
              <el-button type="primary" v-if="grantIsshow" @click="save()" class="ele-btn-icon search">
                <div>提交授权申请</div>
              </el-button>
            </div>
            <div style="padding-top:5rem;" v-else>
              <div>*温馨提示：</div>
              <div>1、可根据代理运营需求选择升级为省级代理运营商、市级代理运营商或增加县级运营商运营授权区／县；</div>
              <div>2、若运营授权查询结果为可代理，可填写对应代理需求进行提交，平台收到提交申请后会及时处理；</div>
              <div>3、运营代理授权需要填写新授权的账号（手机号）；</div>
            </div>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script src="../../../js/shandong.js"></script>
<script>
  import cityMap from "@/js/china-main-city-map.js";
  import echarts from "echarts";
  import axios from "axios";
  import map from "../../../components/map";
  //中国地图（第一级地图）的ID、Name、Json数据
  var chinaId = 100000;
  var chinaName = "china";
  var chinaJson = null;

  //记录父级ID、Name
  var mapStack = [];
  var parentId = null;
  var parentName = null;
  //Echarts地图全局变量，主要是在返回上级地图的方法中会用到
  var myChart = null;
  export default {
    props: {
      msg: String
    },
    name: "SysUser",
    data() {
      return {
        grantClass: false,
        grantIsshow: false,
        authQuery: false,
        isShow: false,
        currIndex:1, //1:省份2：城市3：区
        zoom: 16,
        center: [114.397169, 30.50576],
        list: [],
        provArr: [], // 省列表
        cityArr: [], //市列表
        districtArr: [], //区列表
        editForm: {},
        proName: '',
        proType: '',
        ymoney: 0,
        EchinaID:'',
        EcityID:'',
        EcountryID:[],
        Epid:{},
        Ecid:{},
        Eaid:{},
				editRules: { // 表单验证规则
					pid: [{
						required: true,
						message: '请选择省份',
						trigger: 'change'
					}],
					phone: [{
						required: true,
						message: '请输入新运营授权账号',
						trigger: 'blur'
					},{
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, 
						message: '请输入正确的手机号', 
						trigger: 'blur',
					}],
				},
      }
    },
    created() {
      //获取省份
      this.$http.get('/admin/open_province_list', {
        status: 0
      }).then(res => {
        this.provArr = JSON.parse(res.data)
      })

    },
    //computed: {
    //  ...mapGetters(["permission"]),
    //},
    mounted: function() {
      var myChart = this.$echarts.init(document.getElementById("map"));
      this.mapChart(myChart,chinaId,chinaId);
    },
    methods: {
			initMap(){
				var myChart = this.$echarts.init(document.getElementById("map"));
				this.mapChart(myChart,chinaId,chinaId);
			},
			chongzhi(){
				this.editForm = {}
				this.isShow = false
				this.initMap();
			},
      back() {
        if (mapStack.length != 0) {
          //如果有上级目录则执行
          var map = mapStack.pop();
          axios
						.get("/city/get_map?chinaId=" + map.mapId, {})
						.then(response => {
							const mapJson = response.data;
							this.registerAndsetOption(myChart, map.mapId, map.mapName, mapJson, false);
							//返回上一级后，父级的ID、Name随之改变
							parentId = map.mapId;
							parentName = map.mapName;
						});
        }
      },
      mapChart(myChart,map_id,chinaId,name) {
        var that = this;
        axios.get("/city/get_map?chinaId=" + map_id, {}).then(response => {
          const mapJson = response.data;
          chinaJson = mapJson;
          //console.log(this.editForm.pid)
          //console.log(this.editForm.cid)
          //console.log(response.data)
          if(this.editForm.pid==undefined && this.editForm.cid==undefined){
            // 初始化 中国地图
            this.registerAndsetOption(myChart, chinaId, chinaName, mapJson, false);
          }else{
            // 下拉框选择地区  地图显示
            this.registerAndsetOption(myChart, map_id, name, mapJson, false);
          }
          myChart.off('click') //防止多次点击
          myChart.on("click", function(param) {
            //console.log(cityMap[param.name])
            var cityId = cityMap[param.name];
            var map_type = param.data.type;
						var aidName = param.name;
              if(cityId){
                if(map_type =='pid'){
                  that.editForm.pid = parseInt(cityId);
                  axios.post('/admin/open_city_list', {pid: that.editForm.pid,status: 0,type:1}).then(res => {
                    let data = JSON.parse(res.data)
                    //console.log(data);
                    that.cityArr = data[0];
                    /** 选择省份清空市县**/
                    that.editForm.cid = '';
                    that.editForm.aid = ''
                  })
                }else if(map_type =='cid'){
                  that.editForm.cid = parseInt(cityId);
                  that.cityArr.forEach(function(item) {
                    if (item.cid == that.editForm.cid) {
                      that.districtArr = item.area_list
                      that.proName = item.name
                      that.proType = '市'
                    }
                  })
                  that.editForm.aid=''
                }else{
                  that.$message.error('类型错误');
                }
                axios.get("/city/get_map?chinaId=" +  cityId, {}).then(response => {
                  const mapJson = response.data;
                  that.registerAndsetOption(myChart, cityId, param.name, mapJson, true);
                });
              }else if(map_type =='aid'){
									//console.log("districtArr",that.districtArr)
                  that.districtArr.forEach(function(item) {
                    if (item.name == aidName) {
											that.EcountryID = []
											that.editForm.aid = item.aid
											that.editForm.aid1 = item.name
											// that.$message.error('请在下拉框中选择县/区');
                      that.proName = item.name
											that.editForm.num = that.editForm.aid1.split(',').length
											that.editForm.aidArr = item.name
											that.ymoney = that.editForm.num * 6.78
                      that.proType = '区'
                    }
                  })
									//console.log("item",that.editForm.aid)
							}else{
                that.$message.error('内容错误');
                //没有下级地图，回到一级中国地图，并将mapStack清空
                that.registerAndsetOption(myChart, chinaId, chinaName, chinaJson, false);
              }
          });
        });
      },
			// 选择省
      handleChangeProv(e) {
        var that = this
        if (this.editForm.pid) {
          this.grantClass = true
        } else {
          this.grantClass = false
        }
        /** 获取被选省省份的pid**/
        let pid = ''
        this.provArr.forEach(function(item) {
          if (item.pid == e) {
            pid = item.pid
            that.proName = item.name
            that.proType = '省'
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        let param = {
          pid: pid,
          status: 0,
					type:1
        }
        that.Epid.id = pid
        that.Epid.name = that.proName
        var myChart = this.$echarts.init(document.getElementById("map"));
        this.mapChart(myChart,pid,chinaId,that.Epid.name);
        this.$http.post('/admin/open_city_list', param).then(res => {
          let data = JSON.parse(res.data)
          //console.log(data);
          this.cityArr = data[0]
          /** 选择省份清空市县**/
          this.editForm.cid = ''
          this.editForm.aid = ''
        })
      },
			// 选择市
      handleChangeCity(e) {
        //console.log(1111);
				if(e){
        var that = this
        if (this.editForm.pid && this.editForm.cid) {
          this.grantClass = true
        } else {
          this.grantClass = false
        }
        this.authQuery = false
        var myChart = this.$echarts.init(document.getElementById("map"));
        this.mapChart(myChart,e,chinaId);
        this.cityArr.forEach(function(item) {
          if (item.cid == e) {
            that.districtArr = item.area_list
            that.proName = item.name
            that.proType = '市'
          }
        })
        this.editForm.aid = ''
				}else{
					this.editForm.pid = ''
					this.editForm.aid = ''
				}
      },
      // 选择区
      handleDis(e) {
				if(!this.editForm.aid){
					this.isShow = false
				}
        var that = this
        this.authQuery = true
        this.editForm.aid1 = []
        this.editForm.num = ''
        this.editForm.aidArr = ''
        if (this.editForm.pid && this.editForm.cid && this.editForm.aid) {
          this.grantClass = true
          that.Ecid.id = this.editForm.cid
        } else {
          this.grantClass = false
        }
        this.districtArr.forEach(function(item) {
          if (item.aid == e) {
            that.editForm.aid1 = item.name
            that.proName = item.name
            that.editForm.num = that.editForm.aid1.split(',').length
						that.editForm.aidArr = item.name
            that.ymoney = that.editForm.num * 6.78
            that.proType = '区'
          }
        })
      },
      // 授权查询
      handleGrant(e) {
				this.grantIsshow = true
				this.isShow = true
				var that = this
				if (this.editForm.pid && this.editForm.cid != '' && this.editForm.aid != '') { //不可点击(只选择省份，或者 省市区都选择)
					this.authQuery = true
				} else if (this.editForm.pid != '' && this.editForm.cid != '' && this.editForm.aid != '') {
					this.authQuery = true
				} else {
					this.authQuery = false
				}
				this.districtArr.forEach(function(item) {
					if (item.aid == e) {
						that.editForm.aid1 = item.name
						that.proName = item.name
						that.editForm.num = that.editForm.aid1.split(',').length
						that.editForm.aidArr = that.editForm.aid1
						that.ymoney = that.editForm.num * 6.78
						that.proType = '区'
					}
				})
      },
      //区 多选
      handleChangeXQ() {
        this.editForm.num = this.editForm.aid1.length
        this.ymoney = this.editForm.num * 6.78
				var aid1 = this.editForm.aid1
				var aidArr = []
				this.districtArr.forEach(function(item) {
					aid1.forEach(function(item1){
						if (item.aid == item1) {
							aidArr.push(item.name)
						}
					})
        })
				this.editForm.aidArr = aidArr.toString()
      },
      registerAndsetOption(myChart, id, name, mapJson, flag) {
        echarts.registerMap(name, mapJson);
        //console.log(name);
        //console.log(mapJson);
				var dataArr = this.initMapData(mapJson)
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: function(data){
              if(data.data.type !== 'aid'){
                return data.name+'<br/>已开通'+data.data.count+'个';
              }else{
                return data.name;
              }
            }
          }, // 鼠标移到图里面的浮动提示框
          dataRange: {
            splitList: [
              {start: 1, end: 1000, label: '开通', color: '#1dc199'},
              {start: 0, end: 0.99, label: '未开通', color: '#eee'}
            ],
            textStyle: {
              color: '#1dc199' ,// 值域文字颜色
							fontSize:12,
            },
						right:0,
						bottom:50,
						borderWidth:1,
						borderColor:'#1dc199',
						padding:10
          },
					
					geo: [
						{
							// 地理坐标系组件
							map: name,
							roam: true, // 可以缩放和平移
							aspectScale: 0.80, // 比例
							layoutCenter: ["50%", "50%"], // position位置
							layoutSize: 500, // 地图大小
							// center:[106.567492,29.589036],
							label: {
								// 图形上的文本标签
								normal: {
									show: true,
									textStyle: {
										color: "rgba(0, 0, 0, 0.9)",
										fontSize: '12'
									}
								},
							},
							// 隐藏南海诸岛
							regions: [
								{
									name: "南海诸岛",
									itemStyle: {
										// 隐藏地图
										normal: {
											opacity: 0, // 为 0 时不绘制该图形
										}
									},
									label: {
										show: false // 隐藏文字
									}
								}
							],
							itemStyle: {
								// 图形上的地图区域
								normal: {
									areaColor: "rgba(23, 27, 57,0)",
									borderColor: "#1dc199",
									borderWidth: 1,
								},
							}
						}
					],
          series: [
            {
              type: "map",
							geoIndex:0,
              data:dataArr
            }
          ]
        },true);
      },
      initMapData(mapJson) {
        let mapData = [];
        for (var i = 0; i < mapJson.features.length; i++) {
          let name=mapJson.features[i].properties.name;
          let value=mapJson.features[i].properties.value;
          if(value > 0) {
            mapData.push({
              "name": name,
              "value": mapJson.features[i].properties.value,
              "count": mapJson.features[i].properties.count,
              "type": mapJson.features[i].properties.type
            });
          }else{
            mapData.push({
              "name": name,
              "value": 0,
              "count": mapJson.features[i].properties.count,
              "type": mapJson.features[i].properties.type
            });
          }
        }
        return mapData;
      },
      /* 保存编辑 */
      save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						if (this.editForm.aid == '') {
							this.editForm.aid = this.editForm.aid1
						}
						this.$http.post('/adminaudit/edit', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.editForm = {}
								this.ymoney = 0
								this.isShow = false
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
      },
    },
  }
</script>

<style scoped>
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    margin-left: 13rem;
    margin: 0px auto 40px;
  }

  .search {
    height: 40px;
    width: 288px;
    margin: 20px auto 40px;
    position: relative;
    left: 50%;
    margin-left: -144px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #C6C6C6;
  }

  .content {
    color: #33cc99;
    text-align: center;
    margin-bottom: 20px
  }

  .marleft /deep/.el-form-item__label {
    text-align: left !important
  }

  .noleft /deep/.el-form-item__label {
    text-align: right !important
  }

  .gray {
    background: #C6C6C6;
    color: #fff
  }

  .green {
    background: #33cc99;
    color: #fff
  }
	/deep/.el-select__tags {
		flex-wrap: nowrap;
		overflow: hidden;
	}
</style>

